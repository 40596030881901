import React from 'react'
import { graphql } from 'gatsby'
import { Container, Card } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/FeaturesWithPhoto/Block07'
import ContentOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import ContentTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Features from '@solid-ui-blocks/Features/Block05'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const AboutUs02 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalSimple content={content['privacy-policy']} />
      <ModalSimple content={content['disclaimer']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='6' />
      <Hero content={content['hero']} />
      <Divider space='4' />
      <Features content={content['features']} />
      <Divider space='4' />
    <Container>
        <Card variant='paper-lg' sx={styles.greyContainer}>
          <ContentOne content={content['content-one']} reverse />
          <Divider space='5' />
          <ContentTwo content={content['content-two']} />
        </Card>
      </Container>
      <Divider space='4' />
          <ContentOne content={content['content-three']} />
          <Divider space='5' />
          <ContentTwo content={content['content-four']} reverse />
      <Divider space='5' />
      <Divider space='5' />

      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query siteAboutUs02BlockContent {
    allBlockContent(filter: { page: { in: ["site/about", "site/shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default AboutUs02
