/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  overviewContainer: {
    bg: `omegaDarker`,
    py: 6,
    px: [4, 2]
  },
  greyContainer: {
    py: [5, 6],
    background: t => `linear-gradient(
      150deg,
      ${t.colors.omegaLight} 50%,
      ${t.colors.omega} 100%
    )`
  }
}
